<header>
  <!--<label>{{::header.configuration.companyName}}</label>-->
  <div class="arden-logo float-left">
    <a href="http://www.ardenresourcing.com/"><img class="arden" src="https://www.ardenresourcing.com/wp-content/uploads/2016/02/arden-header-logo.png" style="padding: 15px"></a>
  </div>
  <div class="arden-menu-wrapper float-right">
    <div class="arden-menu">
      <ul>
        <li>
          <a href="http://www.ardenresourcing.com/" target="_blank">Home</a>
        </li>
        <li>
          <a href="http://www.ardenresourcing.com/our-approach/" target="_blank">What We Do</a>
        </li>
        <li>
          <a href="http://www.ardenresourcing.com/our-story/" target="_blank">About Us</a>
        </li>
        <li>
          <a href="http://www.ardenresourcing.com/case-study-mast-ict/" target="_blank">Case Studies</a>
        </li>
        <li>
          <a href="http://www.ardenresourcing.com/our-view/" target="_blank">Our View</a>
        </li>
        <li>
          <a href="http://www.ardenresourcing.com/contact/" target="_blank">Contact Us</a>
        </li>
        <li>
          <a href="http://jobs.ardenresourcing.com">Search Jobs</a>
        </li>
      </ul>
    </div>
  </div>
</header>
<div>
  <router-outlet></router-outlet>  
</div>